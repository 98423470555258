import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: "/",
    redirect: '/home'
  },
  {
    path: "/home",
    component: () => import("@/views/Home/index"),
  },
  {
    path: "/login",
    component: () => import("@/views/Login/index")
  },
  {
    path: "/welcome",
    component: () => import("@/views/WelCome/index")
  },
  {
    path: "/mianze", 
    component: () => import("@/views/MianZe/index")
  },
  {
    path: '/yinsi',
    component: () => import("@/views/YinSi/index")
  },
  {
    path: '/dataprotect',
    component: () => import("@/views/DataProtect/index")
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});

export default router;

