import Vue from 'vue'

import App from './App'
import store from './store'
import router from './router'
import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import './assets/style/global.css'
import Toast from './component/Toast/index.js'
import { catchToken } from '@/utils/index'
import { auth } from './api/login'
import Cookies from 'js-cookie'

Vue.config.productionTip = false
Vue.prototype.$toast = Toast

router.beforeEach((to, from, next) => {
    // 用to.query兼容不好
  let url = window.location.href
  // 用户来源，特殊符号不可匹配
  if(/source\=[0-9A-Za-z]+/.test(url)){
    Cookies.set("source",url.match(/source\=[0-9A-Za-z]+/)[0].slice(7))
  }
  if(/\?code\=[0-9A-Za-z]+/.test(url)){
    catchToken(url.match(/\?code\=[0-9A-Za-z]+/)[0].slice(6)).then(() => {
      window.location.href = url.replace(/\?code\=[0-9A-Za-z]+\&state=/,'')
    }).then(() => {
      next()
    })
  }else{
    next()
  }
})


// 安装虚拟滚动条
Vue.use(VueVirtualScroller)

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
