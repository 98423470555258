// import { sendDialogAI, sendDialogBE, feedback } from '@/api/dialog'
import { sendDialogAI, sendDialogBE } from '@/api/dialog'

/**
 * 单条文本格式化
 * @param {boolean} isUser 是否用户
 * @param {string} currentId 问答记录ID
 * @param {number} type // 1为简单文本，2为链接文本
 * @param {array,string} text // 文本详细内容
 * @param {array} extraText // 所有可选项数据
 */
function formatText({currentId=0, simpleText=false, isUser=false, extraText=[], ...args}){
    return {
        isUser,
        currentId, 
        type: simpleText ? 1 : 2, 
        text: simpleText ? args["Text"] ?? '' : [
            {
                type: "text",
                content: {
                    LeadingMessage:null,
                    EndingMessage:null,
                    Label:null,
                    Text:null,
                    Options:null,
                    ...args
                }
            }
        ],
        extraText // 所有可选项数据
    }
}

async function transmit(commit,payload){
    const res = await sendDialogAI(payload)
    const { data } = await sendDialogBE({
        session_id: res.sessionId,
        question: res.queryResult.queryText,
        answer: JSON.stringify(JSON.parse(res.queryResult.responseText[0].content)),
        user_id: state.userId
    })
    res.sessionId && commit('SET_SESSION_ID', res.sessionId)
    //添加AI对话数据
    if(res.queryResult.responseText.length){
        let text = res.queryResult.responseText
        let type = 1
        text.map(item => {
            item.content = JSON.parse(item.content)
            if(item.content.Options.length){
                type = 2
            }
        })
        let payload = {
            isUser: false,
            type,
            currentId: data.id,
            text,
        }
        for(let item of res.queryResult.outputContexts){
            if(item.name.indexOf("dymextra") != -1){
                let arr = []
                for(let item of res.queryResult.outputContexts[2]?.options){
                    item.displayText && arr.push(item.displayText)
                }
                payload.extraText = arr
                break
            }
        }
        commit("ADD_SESSION_DATA",payload)
    }
    return {
        data: res,
        id: data.id
    }
}

const state = {
    // 用户ID
    userId: '',
    // 对话ID
    sessionId: '',
    // 当前session所有对话信息，遍历渲染
    sessionData: []
}


const mutations = {
    SET_USER_ID: (state, params) => {
        state.userId = params
    },
    SET_SESSION_ID: (state, params) => {
        state.sessionId = params
    },
    ADD_SESSION_DATA: (state, params) => {
        state.sessionData = [
            ...state.sessionData,
            params
        ]
    },
}

const actions = {

    // 发送对话或者选择内容，上报
    dialogPipeline({ commit }, params) {
        return new Promise((resolve, reject) => {
            const { isContinue, ...dialogMessage } = params
            // 添加用户对话数据
            commit("ADD_SESSION_DATA", formatText({simpleText:true,isUser:true,Text:params.queryText}))
            const payload = {
                sessionId: isContinue ? state.sessionId : '',
                actionAreaId: 'default',
                channelId: 'default',
                input: {
                    query: {
                        ...dialogMessage,
                    }
                },
                // TODO: 修改agentId的生成方法
                agentId:'8c457b8f-fd5f-4c95-979d-b1ccbffed775'
            }
            transmit(commit,payload)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
        })
    },

    //更多上下文结果查询
    async showMoreOptions({ commit }, params){
        //获取当前点击的那条记录所有数据
        let payload = JSON.parse(JSON.stringify(params))
        if(payload.text && payload.text.length){
            payload.text[0].content.Options = [...payload.extraText]
            payload.extraText = []
            payload.type = 2
            const { data } = await sendDialogBE({
                session_id: state.sessionId,
                question: "查看更多问题",
                anwser: JSON.stringify(payload.text[0].content),
                user_id: state.userId
            })
            payload.currentId = data.id
            commit("ADD_SESSION_DATA",payload)
        }
    },

    //点赞和点踩，bot无此项功能，只用于后台系统进行上报
    // doFeedback({ commit }, params) {
    //     return new Promise((resolve, reject) => {
    //         // 据type是否为2，显示点赞、点踩UI；参数格式如下
    //         // params = {
    //         //     user_id,
    //         //     message_id: current_id,
    //         //     is_like
    //         // }
    //         feedback(params)
    //         .then(() => {
    //             // 机器人回复数据插入一条“感谢您的反馈！”；UI图标改变状态
    //             commit("ADD_SESSION_DATA", formatText({simpleText:true,isUser:false,Text:"感谢您的反馈！"}))
    //             resolve()
    //         })
    //         .catch(err => {
    //             reject(err)
    //         })
    //     })
    // },


}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}