import axios from "axios";
import Cookies from 'js-cookie';
import { isWechatEnv } from './index'
import Toast from '../component/Toast'

//1. 创建新的axios实例，
const service = axios.create({
  // 公共地址
  // baseURL: '',
  // 超时时间 单位是ms
  // timeout: 3 * 1000
});

// 2.请求拦截器
service.interceptors.request.use(
  config => {
    // config.headers['Content-Type'] = 'application/json;charset=UTF-8'

    //发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
    // 添加token
    let token = Cookies.get('x-token')
    config.headers['x-token'] = token ? token : ''

    if (config.method === "post") {
      // 处理参数 如果是post请求 修改params参数为data 因为Vue-cli框架中axios参数根据method分为两种
      config["data"] = config["params"];
      delete config["params"];
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);
  
// 3.响应拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    // redirect codes
    let redirectCodes = [11,12]
    if(redirectCodes.indexOf(parseInt(res.code,10)) != -1){
      switch (parseInt(res.code,10)){
        case 11:
          // 未授权，区分微信还是网页环境
          if(isWechatEnv()){
            // redirect to auth url
            if(res.data && window.location.href.indexOf('?code=') == -1){
              window.location.href = res.data
            }
          }else{
            // redirect to login
            window.location.href = "#/login"
          }
          
          break;
        case 12:
          // redirect to login
          window.location.href = "#/login"
          break;
      }

      return Promise.reject(res);
    }
    return res
  },
  error => {
    /***** 接收到异常响应的处理开始 *****/
    if(error && error.response){
      switch (error.response.status){
        case 500:
          // 服务器错误，60s后重载页面
          error.message = '服务器端出错'
          setTimeout(()=>{
            location.reload()
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
          },60000)
          break;
      }
      
    }
    // 提示
    error.message && Toast(error.message)
    return Promise.reject(error);
    
  });
//4.导入文件
export default service;

