<template>
  <div class="toast">
    <div class="toast-image"></div>
    <div class="toast-content">{{message}}</div>
  </div>
</template>

<script>
export default {
  name: 'Toast',
  data() {
    return {
      message: '',
      duration: 1
    }
  },
  mounted() {
    this.timer = setTimeout(() => {
      this.$destroy()
      this.$el.parentNode.removeChild(this.$el)
    }, this.duration * 1000)
  },
  unmounted() {
    clearTimeout(this.timer)
  }
}
</script>

<style lang="less" scoped>
.toast {
  position: fixed;
  top: 53.3333vw;
  left: 50%;
  transform: translateX(-50%);
  width: 45.8667vw;
  opacity: 1;
  height: 45.3333vw;
  border-radius: 1.0667vw;
  background-color: rgba(0, 0, 0, .6);
  z-index: 100;

  .toast-image {
    position: absolute;
    width: 14.2213vw;
    height: 14.2213vw;
    top: 10.488vw;
    left: 50%;
    transform: translateX(-50%);
    background: url('../../assets/img/warning.png') no-repeat center center;
  }

  .toast-content {
    position: absolute;
    top: 30.4vw;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
  }
}
</style>