import request from '@/utils/request'
import {domain} from '@/utils/config'

// 发送验证码
export function sendAliSms(params) {
  return request({
    url: `${domain}web/SendSms/sendAliSms`,
    method: 'post',
    params
  })
}

// 登录
export function login(params) {
  return request({
    url: `${domain}web/users/login`,
    method: 'post',
    params
  })
}

// code2token
export function code2token(params) {
  return request({
    url: `${domain}web/WechatUser/codeToken`,
    method: 'post',
    params
  })
}

// auth
export function auth() {
  return request({
    url: `${domain}web/WechatUser/auth`,
    method: 'get',
  })
}

// 上报用户来源
export function reportSource(params) {
  return request({
    url: `${domain}web/users/markSource`,
    method: 'post',
    params
  })
}

