<template>
  <div id="app">
      <!-- 主体 -->
      <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>
